import React from 'react'
import tw from 'twin.macro'

const Star = ({ id, percentage = 0 }) => {
  return (
    <div tw="inline-block mx-1 lg:(ml-0 mr-2)">
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        tw="mask-image[]"
      >
        <path
          d="M11.7816 1.09424L15.0942 7.64923L22.5021 8.70682L17.1419 13.8063L18.4069 21.0105L11.7816 17.6074L5.15628 21.0105L6.42131 13.8063L1.06104 8.70682L8.46893 7.64923L11.7816 1.09424Z"
          stroke="#FF4D00"
          strokeWidth="0.69697"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={`url(#${id})`}
        />
        <linearGradient id={id} x1="0" y1="0.5" x2="1" y2="0.5">
          <stop offset="0%" stopOpacity="1" stopColor="#FF4D00" />
          <stop
            offset={`${percentage.toString()}%`}
            stopOpacity="1"
            stopColor="#FF4D00"
          />
          <stop
            offset={`${percentage.toString()}%`}
            stopOpacity="0"
            stopColor="#FF4D00"
          />
          <stop offset="100%" stopOpacity="0" stopColor="#FF4D00" />
        </linearGradient>
      </svg>
    </div>
  )
}

export default Star
